import React, { useEffect, useState, useContext } from 'react'
import iconnotification from 'assets/images/svg/icon-notification-org.svg'
import iconBelnoti from 'assets/images/svg/bel-noti.svg'
import CloseNotification from 'assets/images/svg/CloseNotification.svg'
import NotiIcon from 'assets/images/svg/NotiIcon.svg'
import { Button, Popover, Space } from 'antd'
import { getData, getList, postData, putData } from 'core/action/collection'
import { checkColorStatusBg, checkColorStatusText, checkStatusText, Icon } from './container'
import { TextStatus, Content, BoxNoti, ButtonEdit, ButtonSuccess } from './style'
import UserContext from 'core/contexts/userContext'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { complaint } from 'core/schemas'
import { conversDate } from 'core/utils/DateTimeFormat'

export default function Index() {
  const { meData } = useContext(UserContext)
  const [dataNoti, setDataNoti] = useState([])
  const [openNotification, setOpenNotification] = useState(false)
  const navigate = useNavigate()
  const typeNoty = {
    new: `มีการร้องเรียนใหม่ที่เกี่ยวข้องกับคุณ กรุณาตรวจสอบรายละเอียด`,
    transfer: `มีเรื่องร้องเรียนถูกโอนย้ายเข้ามา กรุณาตรวจสอบรายละเอียด`,
    edit: `มีเรื่องร้องเรียนที่ได้รับการแก้ไข กรุณาตรวจสอบรายละเอียด`,
    'no service': `มีการร้องเรียนที่ยังไม่ได้รับบริการ กรุณาตรวจสอบรายละเอียด`,
    duedate: `มีการร้องเรียนที่กำลังจะถึงกำหนด กรุณาตรวจสอบรายละเอียด`,
    wait_transfer: `มีเรื่องร้องเรียนที่ถูกโอนย้ายผู้รับผิดชอบ`,
    accept_transfer: `โอนย้ายผู้รับผิดชอบสำเร็จ`,
    deny_transfer: `การโอนย้ายถูกปฏิเสธ`,
  }

  useEffect(() => {
    getAllDataComplant()
  }, [openNotification])
  const getAllDataComplant = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/noti/staff/all?limit=10',
      params: {
        filters: [
          meData?.position_data?.id === '1730459515121299456'
            ? undefined
            : meData?.position_data?.id === '1730459515154853888'
            ? `department:eq:${meData?.organize_data?.org_id}`
            : `officer:eq:${meData?.id}`,
          ,
        ],
      },
    })
    if (res?.items) {
      setDataNoti(res?.items)
    }
  }

  const updateStusReadSuperAdmin = async NotiId => {
    const bodySuperAdmin = {
      noti_type: 'super_admin',
    }

    try {
      const respUpdate = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/noti/staff/${NotiId}`,
        bodySuperAdmin
      )
    } catch (error) {}
  }

  const updateReadAdminStaffStaff = async NotiId => {
    const bodyAdminStaff = {
      department_id: meData?.organize_data?.org_id,
      noti_type: 'department',
    }

    try {
      const respUpdate = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/noti/staff/${NotiId}`,
        bodyAdminStaff
      )
    } catch (error) {}
  }

  const updateReadStaff = async NotiId => {
    const bodyStaff = {
      noti_type: 'officer',
      officerID: meData?.id,
    }

    try {
      const respUpdate = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/noti/staff/${NotiId}`,
        bodyStaff
      )
    } catch (error) {}
  }

  const checkPathNavigate = async (value, position) => {
    if (value?.complaint) {
      // noti compalint
      if (position === 'Super Admin') {
        await updateStusReadSuperAdmin(value?.id)
        // if (value?.noti_type !== 'wait_transfer') {
        navigate('/complaint/bof-overview-complaint/view', {
          state: {
            record: value?.complaint_id,
            group: value?.is_group,
          },
        })
        // }
      } else if (position === 'Admin Staff') {
        await updateReadAdminStaffStaff(value?.id)
        navigate('/complaint-staff/bof-overview-complaint/view', {
          state: {
            record: value?.complaint_id,
            group: value?.is_group,
          },
        })
      } else if (position === 'Staff') {
        await updateReadStaff(value?.id)
        navigate('/complaint-staff/bof-overview-complaint/view', {
          state: {
            record: value?.complaint_id,
            group: value?.is_group,
          },
        })
      }
    } else {
      //noti  eservice
      if (position === 'Admin Staff') {
        await updateReadAdminStaffStaff(value?.id)
        navigate('/e-service/bof-eservice/view', {
          state: {
            record: value?.complaint_id,
            group: value?.is_group,
          },
        })
      } else {
        await updateReadStaff(value?.id)
        navigate('/e-service/bof-eservice/view', {
          state: {
            record: value?.complaint_id,
            group: value?.is_group,
          },
        })
      }
    }
  }

  let token = localStorage.getItem('access_token')

  const onClickAccept = async value => {
    if (value?.complaint === true) {
      const getOfficer = async () => {
        const resOfficer = await axios.get(
          `${process.env.REACT_APP_BASEURL}/complaint/api/v1/officer/${value?.complaint_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        try {
          if (resOfficer?.status === 200 || 201) {
            const payloadAccept = {
              department_id: meData?.organize_data?.org_id,
              staff_by: meData?.id,
            }

            const res = await putData(
              `${complaint}/officer`,
              resOfficer?.data?.data?.complaint_data[0]?.officer_data?.id,
              payloadAccept
            )

            if (res) {
              if (res?.code === 201 || 200) {
                const payloadNotiAccept = {
                  complaint_id: value?.complaint_id,
                  department_id: meData?.organize_data?.org_id,
                  noti_type: 'accept_transfer',
                  staff_receive: value?.staff_receive,
                  staff_send: value?.staff_send,
                }

                const res = await postData(`${complaint}/transfer/officer`, payloadNotiAccept)

                if (res) {
                  if (res?.code === 200 || 201) {
                    // getAllDataComplant()
                    window.location.reload()
                  }
                }
              }
            }
          }
        } catch (error) {}
      }
      getOfficer()
    } else {
      const getOfficerEserivce = async () => {
        const resOfficer = await axios.get(
          `${process.env.REACT_APP_BASEURL}/complaint/api/v1/e-service/officer/${value?.complaint_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        try {
          if (resOfficer?.status === 200 || 201) {
            const payloadAccept = {
              department_id: meData?.organize_data?.org_id,
              staff_by: meData?.id,
            }

            const res = await putData(
              `${complaint}/e-service/officer`,
              resOfficer?.data?.data?.complaint_data[0]?.officer_data?.id,
              payloadAccept
            )

            if (res) {
              if (res?.code === 201 || 200) {
                const payloadNotiAccept = {
                  complaint_id: value?.complaint_id,
                  department_id: meData?.organize_data?.org_id,
                  noti_type: 'accept_transfer',
                  staff_receive: value?.staff_receive,
                  staff_send: value?.staff_send,
                }
                const res = await postData(`${complaint}/transfer/e-service/officer`, payloadNotiAccept)
                if (res) {
                  if (res?.code === 200 || 201) {
                    // getAllDataComplant()
                    window.location.reload()
                  }
                }
              }
            }
          }
        } catch (error) {}
      }
      getOfficerEserivce()
    }
  }

  const onClickReject = async value => {
    if (value?.complaint === true) {
      const payloadNotiReject = {
        complaint_id: value?.complaint_id,
        department_id: meData?.organize_data?.org_id,
        noti_type: 'deny_transfer',
        staff_receive: value?.staff_receive,
        staff_send: value?.staff_send,
      }
      const res = await postData(`${complaint}/transfer/officer`, payloadNotiReject)
      if (res) {
        if (res?.code === 200 || 201) {
          window.location.reload()
          getAllDataComplant()
        }
      }
    } else {
      const payloadNotiReject = {
        complaint_id: value?.complaint_id,
        department_id: meData?.organize_data?.org_id,
        noti_type: 'deny_transfer',
        staff_receive: value?.staff_receive,
        staff_send: value?.staff_send,
      }
      const res = await postData(`${complaint}/transfer/e-service/officer`, payloadNotiReject)
      if (res) {
        if (res?.code === 200 || 201) {
          window.location.reload()
        }
      }
    }
  }

  function formatTimeDifference(dataTime) {
    const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
    const difference = currentTime - dataTime // Time difference in seconds
    if (difference < 60) {
      // Less than 1 minute (60 seconds)
      return `ตอนนี้`
    } else if (difference < 3600 && difference >= 60) {
      // Less than 1 hour (3600 seconds)
      const minutes = Math.floor(difference / 60)
      return `${minutes} นาที`
    } else if (difference < 86400) {
      // Less than 1 day (86400 seconds)
      const hours = Math.floor(difference / 3600)
      return `${hours} ชั่วโมง`
    } else if (difference < 172800) {
      // Between 24 and 48 hours
      const days = Math.floor(difference / 86400)
      return `${days} วัน`
    } else {
      return conversDate(dataTime)
    }
  }

  const content = (
    <div style={{ fontFamily: 'Prompt' }}>
      <div style={{ maxHeight: '700px', overflow: 'auto', padding: '5px', minWidth: '350px' }}>
        {dataNoti?.length > 0
          ? dataNoti?.map((value, index, array) => {
              return (
                <BoxNoti
                  key={index}
                  onClick={e => {
                    setOpenNotification(false)
                    checkPathNavigate(value, meData?.position_data?.name)
                  }}
                >
                  <div
                    key={index}
                    style={{ marginBottom: '10px', padding: '10px', borderRadius: '10px' }}
                    className="box-noti"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div
                        style={{
                          width: '80%',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          '-webkit-line-clamp': 2,
                          '-webkit-box-orient': 'vertical',
                          fontSize: '12px',
                        }}
                      >
                        {typeNoty[value?.noti_type]}
                      </div>
                      <div style={{ fontSize: '12px', color: '#C4C4C4' }}>
                        {/* {formatTimeDifference(value?.status_data?.created_at)} */}
                        {formatTimeDifference(value?.created_at)}
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <img src={value?.category?.icon_url} style={{ width: '20px', height: '20px' }} />
                        <div>{value?.category?.name}</div>
                      </div>
                      <div>
                        <TextStatus
                          bg={value?.status_data?.name ? checkColorStatusBg(value?.status_data?.name) : ''}
                          text={value?.status_data?.name ? checkColorStatusText(value?.status_data?.name) : ''}
                        >
                          {value?.status_data?.name
                            ? value?.status_data?.name
                              ? checkStatusText(value?.status_data?.name)
                              : '-'
                            : '-'}
                        </TextStatus>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {value?.complaint ? (
                        <div>
                          <div>
                            <div style={{ fontSize: '10px', color: '#C4C4C4' }}>ประเภทย่อย</div>
                            <div> {value?.sub_category_name}</div>
                          </div>
                          <div>
                            <div style={{ fontSize: '10px', color: '#C4C4C4', marginBottom: '10px' }}>
                              สถานะความเร่งด่วน
                            </div>
                            {value?.urgency?.name ? (
                              <span
                                style={{
                                  backgroundColor: value?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
                                  borderRadius: '10px',
                                  color:
                                    value?.urgency?.name === 'ปกติ'
                                      ? '#13AFA6'
                                      : value?.urgency?.name === 'ด่วน'
                                      ? '#FFA643'
                                      : value?.urgency?.name === 'ด่วนมาก'
                                      ? '#EC82F8'
                                      : value?.urgency?.name === 'ด่วนที่สุด'
                                      ? '#F36B24'
                                      : '#FFFFFF',
                                  padding: '5px 5px',
                                  height: '20px',
                                  fontSize: '12px',
                                }}
                              >
                                {value?.urgency?.name}
                              </span>
                            ) : (
                              '-'
                            )}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        {value?.media_url ? (
                          value?.media_url[0]?.includes('.mp4') ? (
                            <div style={{ width: '144px', height: '82px', borderRadius: '10px' }}>
                              <video style={{ width: '100%', height: '100%', borderRadius: '10px' }} controls>
                                <source src={value?.media_url[0]} type="video/mp4" />
                              </video>
                            </div>
                          ) : (
                            <img
                              src={value?.media_url}
                              style={{ width: '144px', height: '82px', borderRadius: '10px' }}
                            />
                          )
                        ) : (
                          <img
                            src={Icon.filter(val => val.name === value.category.name)[0]?.icon}
                            style={{ width: '144px', height: '82px', borderRadius: '10px' }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {value?.noti_type === 'wait_transfer' &&
                    value?.staff_receive === meData?.id &&
                    meData?.position_data?.id !== '1730459515121299456' && (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ButtonEdit
                          style={{ margin: '0 1em' }}
                          onClick={e => {
                            e.stopPropagation()
                            onClickReject(value)
                          }}
                        >
                          ปฎิเสธ
                        </ButtonEdit>

                        <ButtonSuccess
                          type="primary"
                          onClick={e => {
                            e.stopPropagation()
                            onClickAccept(value)
                          }}
                        >
                          ยอมรับ
                        </ButtonSuccess>
                      </div>
                    )}
                  <hr style={{ border: '1px solid #FFEEE7' }} />
                </BoxNoti>
              )
            })
          : 'ไม่มีการแจ้งเตือน'}
      </div>
    </div>
  )

  return (
    <Content>
      {meData?.position_data?.id === '1730459515121299456' ? (
        <Popover content={content} visible={openNotification} placement="bottomRight" className={'notification'}>
          {dataNoti?.find(item => item?.is_read === false || item?.is_read === null) ? (
            //มีเขียว
            <>
              <img
                src={openNotification ? CloseNotification : iconnotification}
                style={{
                  marginTop: openNotification ? '-10px' : '-25px',
                  marginRight: openNotification ? '18px' : '10px',
                  marginBottom: openNotification ? '20px' : '0',
                }}
                onClick={() => setOpenNotification(!openNotification)}
                tabIndex={0}
              />
            </>
          ) : (
            //ไม่มีเขึยว
            <img
              src={openNotification ? CloseNotification : NotiIcon}
              style={{
                height: openNotification ? '30px' : '22px',
                marginTop: openNotification ? '5px' : '10px',
                marginRight: openNotification ? '18px' : '30px',

                marginBottom: openNotification ? '10px' : '20px',
              }}
              onClick={() => setOpenNotification(!openNotification)}
              tabIndex={0}
            />
          )}
        </Popover>
      ) : (
        <Popover content={content} visible={openNotification} placement="bottomRight" className={'notification'}>
          {dataNoti?.find(
            item => item?.is_read === false || item?.is_read === null || item?.noti_type === 'wait_transfer'
          ) ? (
            //มีเขียว
            <>
              <img
                src={openNotification ? CloseNotification : iconnotification}
                style={{
                  marginTop: openNotification ? '-10px' : '-25px',
                  marginRight: openNotification ? '18px' : '10px',
                  marginBottom: openNotification ? '20px' : '0',
                }}
                onClick={() => setOpenNotification(!openNotification)}
                tabIndex={0}
              />
            </>
          ) : (
            //ไม่มีเขึยว
            <img
              src={openNotification ? CloseNotification : NotiIcon}
              style={{
                height: openNotification ? '30px' : '22px',
                marginTop: openNotification ? '5px' : '10px',
                marginRight: openNotification ? '18px' : '30px',

                marginBottom: openNotification ? '10px' : '20px',
              }}
              onClick={() => setOpenNotification(!openNotification)}
              tabIndex={0}
            />
          )}
        </Popover>
      )}
    </Content>
  )
}

const BoxCustomBel = styled.div``
