import Icon1 from './category/ไฟฟ้าถนนทางเท้า.svg'
import Icon2 from './category/ฝาท่อรางระบายน้ำ.svg'
import Icon3 from './category/ประปา.svg'
import Icon4 from './category/เหตุรำคาญ.svg'
import Icon5 from './category/ขยะ.svg'
import Icon6 from './category/สาธารณภัย.svg'
import Icon7 from './category/เทศกิจ.svg'
import Icon8 from './category/เบี้ยยังชีพ.svg'
import Icon9 from './category/สาธารณสุข.svg'
import Icon10 from './category/ภาษี.svg'
import Icon11 from './category/ทะเบียนราษฎร์.svg'
import Icon12 from './category/อื่นๆ.svg'
import IconE1 from './category/บริการรถดูดโคลนเลน.svg'
import IconE2 from './category/บริการรถดับเพลิง.svg'
import IconE3 from './category/บริการถังขยะ.svg'
import IconE4 from './category/ขอรับรองการประกอบอาชีพ OTOP.svg'
import IconE5 from './category/ขอจำหน่ายสินค้าตลาดนัด.svg'

export const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
  if (val === 'transfer') {
    return '#EAEAEB'
  }
}

export const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
  if (val === 'transfer') {
    return '#717171'
  }
}

export const checkStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
  if (val === 'outside the area') {
    return 'ไม่อยู่ในความรับผิดชอบ'
  }
  if (val === 'tracking') {
    return 'ติดตาม'
  }
  if (val === 'over duedate') {
    return 'เกินระยะเวลากำหนด'
  }
  if (val === 'transfer') {
    return 'โอนย้ายหน่วยงาน'
  }
}

export const Icon = [
  {
    name: 'ไฟฟ้า/ถนน/ทางเท้า',
    icon: Icon1,
  },
  {
    name: 'ฝาท่อ/รางระบายน้ำ',
    icon: Icon2,
  },
  {
    name: 'ประปา',
    icon: Icon3,
  },
  {
    name: 'เหตุรำคาญ',
    icon: Icon4,
  },
  {
    name: 'ขยะ',
    icon: Icon5,
  },
  {
    name: 'สาธารณภัย',
    icon: Icon6,
  },
  {
    name: 'เทศกิจ',
    icon: Icon7,
  },
  {
    name: 'เบี้ยยังชีพ',
    icon: Icon8,
  },
  {
    name: 'สาธารณสุข',
    icon: Icon9,
  },
  {
    name: 'ภาษี',
    icon: Icon10,
  },
  {
    name: 'ทะเบียนราษฎร์',
    icon: Icon11,
  },
  {
    name: 'อื่นๆ',
    icon: Icon12,
  },
  {
    name: 'บริการรถดูดโคลนเลน',
    icon: IconE1,
  },
  {
    name: 'บริการรถดับเพลิง',
    icon: IconE2,
  },
  {
    name: 'บริการถังขยะ',
    icon: IconE3,
  },
  {
    name: 'ขอรับรองการประกอบอาชีพ OTOP',
    icon: IconE4,
  },
  {
    name: 'ขอจำหน่ายสินค้าตลาดนัด',
    icon: IconE5,
  },
]

const d = [
  {
    uuid: '3a47d898-ddfa-41ec-9cdc-c09c4a44318a',
    username: 'admin123',
    last_login: '2024-12-24T10:42:56.000Z',
    first_login: false,
    password: '$2b$10$xKGpw9bLhNpckGTYnsN9xeV5xQoeq2/MaplWKTGhQlu0OLLUm2c0G',
    prev_password: null,
    old_password: null,
    firstname_en: 'admin',
    lastname_en: 'touch',
    firstname_th: 'แอดมิน',
    lastname_th: 'ทัช',
    hosxp_username: '-',
    cid: '1223234345435',
    birthdate: '2024-10-31T17:00:00.000Z',
    position: 'qa',
    medpro_number: null,
    start_date: '2024-10-31T17:00:00.000Z',
    created_at: '2024-11-29T09:00:52.000Z',
    updated_at: '2024-12-24T10:42:53.000Z',
    deleted_at: null,
    group_name: 'Admin',
    group_id: 1,
    org_name: 'touch',
    organization_id: 123,
    role_name: 'admin',
    role_id: 1,
    firstname: { th: 'แอดมิน', en: 'admin' },
    lastname: { th: 'ทัช', en: 'touch' },
    permissions: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
    ],
    iat: 1735036978,
    exp: 1735044178,
  },
  {
    uuid: '3a47d898-ddfa-41ec-9cdc-c09c4a44318a',
    username: 'admin123',
    last_login: '2024-12-24T10:53:04.000Z',
    first_login: false,
    password: '$2b$10$xKGpw9bLhNpckGTYnsN9xeV5xQoeq2/MaplWKTGhQlu0OLLUm2c0G',
    prev_password: null,
    old_password: null,
    firstname_en: 'admin',
    lastname_en: 'touch',
    firstname_th: 'แอดมิน',
    lastname_th: 'ทัช',
    hosxp_username: '-',
    cid: '1223234345435',
    birthdate: '2024-10-31T17:00:00.000Z',
    position: 'qa',
    medpro_number: null,
    start_date: '2024-10-31T17:00:00.000Z',
    created_at: '2024-11-29T09:00:52.000Z',
    updated_at: '2024-12-24T10:42:53.000Z',
    deleted_at: null,
    group_name: 'Admin',
    group_id: 1,
    org_name: 'touch',
    organization_id: 123,
    role_name: 'admin',
    role_id: 1,
    firstname: { th: 'แอดมิน', en: 'admin' },
    lastname: { th: 'ทัช', en: 'touch' },
    permissions: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
    ],
    iat: 1735037588,
    exp: 1735044788,
  },
]
