import React from 'react'
import styled from 'styled-components'
export default function ExportSumTotalPdf({ dataSource, componentRefAll, searchData }) {
  const mockData = [
    {
      orgenization: 'สำนักช่าง',
      data: [
        {
          key: '1',
          order: 1,
          type_complaint: 'ไฟฟ้าสาธารณะ',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
        {
          key: '2',
          order: 2,
          type_complaint: 'ถนน/ฟุตบาททางเท้า',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
        {
          key: '3',
          order: 3,
          type_complaint: 'ควบคุมการก่อสร้าง',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
      ],
      total: {
        total: 300,
        done: 300,
        doing: 0,
        percent: 100,
      },
    },
    {
      orgenization: 'สำนักการประปา',
      data: [
        {
          key: '1',
          order: 1,
          type_complaint: 'ความสะอาดของน้ำ',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
        {
          key: '2',
          order: 2,
          type_complaint: 'ท่อประปาแตกชำรุด',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
        {
          key: '3',
          order: 3,
          type_complaint: 'น้ำประปาไม่ไหล,ไหลอ่อน',
          total: 100,
          done: 100,
          doing: 0,
          percent: 100,
        },
      ],
      total: {
        total: 300,
        done: 300,
        doing: 0,
        percent: 100,
      },
    },
  ]
  return (
    <BoxTable ref={componentRefAll}>
      exportNewPdf
      <div>
        <table style={{ width: '100%' }}>
          <tr>
            <th>หน่วยงาน</th>
            <th>ลำดับ</th>
            <th>ประเภทเรื่องร้องเรียน</th>
            <th>จำนวนเรื่อง</th>
            <th>ดำเนินการแล้วเสร็จ</th>
            <th>ระหว่างดำเนินการ</th>
            <th>คิดเป็นร้อยละ</th>
          </tr>
          {mockData?.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td rowSpan={item?.data?.length + 2}>{item?.orgenization}</td>
                </tr>
                {item?.data?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{item?.order}</td>
                        <td>{item?.type_complaint}</td>
                        <td>{item?.total}</td>
                        <td>{item?.done}</td>
                        <td>{item?.doing}</td>
                        <td>{item?.percent}</td>
                      </tr>
                    </>
                  )
                })}
                <tr>
                  <td colSpan="2">รวม</td>
                  <td>{item?.total.total}</td>
                  <td>{item?.total.done}</td>
                  <td>{item?.total.doing}</td>
                  <td>{item?.total.percent}</td>
                </tr>
              </>
            )
          })}
        </table>
      </div>
    </BoxTable>
  )
}

const BoxTable = styled.div`
  padding: 2%;
  font-family: 'Prompt' !important;
  @media print {
    @page {
      size: A4 landscape;
      margin: 20mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  table,
  td,
  th {
    border: 1px solid #000;
    text-align: center;
  }
`
