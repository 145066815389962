import * as Yup from 'yup'

export const defaultValues = {
  address: '',
  category_id: '',
  channel: '',
  complaint_name: '',
  description: '',
  line_id: '',
  mobile_number: '',
  first_name: '',
  last_name: '',
  org_id: '',
  sub_category_id: '',
}

export const Schema = Yup.object().shape({
  first_name: Yup.string().required('').max(255, 'กรุณากรอกไม่เกิน 255 ตัวอักษร'),
  // .matches(/^[ก-๏เ-๙\s]+$/, 'กรุณากรอกภาษาไทยเท่านั้น'),
  last_name: Yup.string().required('').max(255, 'กรุณากรอกไม่เกิน 255 ตัวอักษร'),
  // .matches(/^[ก-๏เ-๙\s]+$/, 'กรุณากรอกภาษาไทยเท่านั้น'),
  // mobile_number: Yup.string().required('').min(11, 'sdfsdfsdfsd'),

  mobile_number: Yup.string()
    .required('')
    .transform(value => value.replace(/-/g, '')) // ลบขีดก่อนการตรวจสอบ
    .matches(/^(0[689]\d{8}|044\d{6})$/, 'รูปแบบข้อมูลไม่ถูกต้อง'),

  category_id: Yup.string().required(''),
  sub_category_id: Yup.string().required(''),
  // complaint_name: Yup.string().required(''),
  channel: Yup.string().required(''),
})

export const SchemaUpdate = Yup.object().shape({
  duedate_at: Yup.date().required(''),
  urgency_id: Yup.string().required(''),
  // department_data: Yup.array().of(Yup.string().required()).min(1, ''),
})
