import React, { useState, useEffect } from 'react'
import * as F from './FormInput.style'
import { CustomPopover } from './FormInput.style'
import { Input } from 'components/Inputs'
import InputFormUser from 'components/input/inputFormUser'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import { defaultValues, Schema, SchemaUpdate } from './container'
import DropdownForm from 'components/Inputs/DropdownForm'
import DropdownForm2 from 'components/Inputs/DropdownForm2'
import DropdownMultiple from 'components/Inputs/DropdownMultiple'
import UploadFile from 'components/Inputs/UploadFile'
import iconInfo from 'assets/images/svg/icon-info.svg'
import Viewmap from './MapSelect'
import MapButton from './GoogleMap/Map'
import { getData, getMe, postData, putData } from 'core/action/collection'
import { masterdata, complaint } from 'core/schemas'
import { Select, Button, Space, Popover, Tooltip, Modal, Form } from 'antd'
import DatePicker from 'components/input/DatePicker'
import ThaiDatePicker from './ThaiDatePicker'
import { ButtonBack } from './view.style'
import moment from 'moment'
import './viewmap.css'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { ReactComponent as IconTitle } from 'assets/images/svg/icon-title-form-input-conplaint.svg'
import { ReactComponent as CloseModelMap } from './image/svg/CloseModelMap.svg'
import BeforeIcon from './image/png/Before Success.png'
import IconClear from 'assets/images/svg/icon-close-drawer.svg'
import styled from 'styled-components'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import NakhonRatchasimaProvice from 'core/utils/NakhonRatchasima_provice.json'
import useGetAddress from 'core/hooks/useGetAddress'

const FormInput = ({ closeInputDrawer, getListData, openNotification, setLeaveModalOpen }) => {
  const getToken = localStorage.getItem('access_token')
  const [formAntd] = Form.useForm()

  const [userData, setUserData] = useState('')

  const [formattedDate, setFormattedDate] = useState('')
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategory] = useState([])
  const [urgency, setUrgency] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [channel, setChannel] = useState([])

  const [cover, setCover] = React.useState([])
  const [submit, setsubmit] = React.useState({})
  const [createSuccess, setCreateSuccess] = useState(false)

  const [complaintFromApi, setComplaintFromApi] = useState('')
  const [comfirmModalOpen, setConfirmModalOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [loaddingCraete, setloaddingCraete] = useState(false)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 })
  const [zoomMap, setZoomMap] = useState(10)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [selectSubCategory, setSelectSubCategory] = useState('')

  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)

  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })
  let arrArearWater = []
  NakhonRatchasimaProvice?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArearWater.push({ lat: item[1], lng: item[0] })
  })
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const formikUpdate = useFormik({
    // initialValues: { duedate_at: '', urgency_id: '', department_data: [] },
    initialValues: { duedate_at: '', urgency_id: '' },
    validationSchema: SchemaUpdate,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setMeLocation({ lat: latitude, lng: longitude })
            setCenterMap({ lat: latitude, lng: longitude })
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
    fetchUserLocation() // Call the function when the component mounts
  }, [])

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const getMeData = async () => {
    const res = await getMe({ token: getToken })

    if (res) {
      if (res?.code === 200) {
        setUserData(res.data)
      }
    }
  }

  const isValidForm = formik.isValid && formik.dirty
  const isValidUpdate = formikUpdate.isValid && formikUpdate.dirty

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/categories`,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }

  const getSubCategory = async () => {
    const res = await getData({
      schema: `${masterdata}/subcategories/` + categoryId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setSubCategory(newData)
    }
  }

  const getUrgency = async () => {
    const res = await getData({
      schema: `${masterdata}/urgency`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setUrgency(newData)
    }
  }

  const getOrganizations = async () => {
    const res = await getData({
      schema: `${masterdata}/organizations`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setOrganizations(newData)
    }
  }

  const getChannel = async () => {
    const res = await getData({
      schema: `${masterdata}/channel`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setChannel(newData)
    }
  }

  const optionsCategories = categories?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    icon_url: items?.icon_url,
    key: index,
  }))

  const optionsSubCategories = subCategories?.map((items, index) => ({
    value: items?.ID,
    label: items?.name,
    key: index,
  }))

  const optionsUrgency = urgency?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const optionsOrganizations = organizations?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const optionsChannel = channel?.map((items, index) => ({
    value: items?.name_en,
    label: items?.name_th,
    key: index,
  }))

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    getMeData()
    DatetimeNowThai()
    getCategories()
    getUrgency()
    getOrganizations()
    getChannel()
  }, [])

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const onClickNext = () => {
    // handlerSubmit()
    if (checkArear(clickedLatLng?.lat && clickedLatLng?.lng ? clickedLatLng : meLocation)) {
      setCreateSuccess(true)
    } else {
      setIsModalOpenError(true)
    }
  }

  const handlerSubmit = async () => {
    const phone = formik.values.mobile_number.replace(/-/g, '')
    const nameCategory = optionsSubCategories?.find(item => item?.value === selectSubCategory)
    const { first_name, last_name, category_id, description, sub_category_id } = formik.values
    const dataurl = []

    cover.map(item => {
      dataurl.push('https://' + item.urlkey)
    })

    const objCategory = categories.find(complaint => complaint.id === categoryId)

    const iconUrl = objCategory?.icon_url

    const tmpValues = {
      address: address[2]?.formatted_address,
      category_id: category_id,
      channel: formik?.values?.channel,
      complaint_name: nameCategory?.label,
      coordinates: {
        latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
        longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
      },
      description: description,
      line_id: '',
      media_url: dataurl.length > 0 ? dataurl : [iconUrl],
      mobile_number: phone,
      name: {
        first_name: first_name,
        last_name: last_name,
      },
      org_id: '19118133238380',
      status_data: [
        {
          description: '',
          name: 'received',
        },
      ],
      sub_category_id: sub_category_id,
    }

    const res = await postData(`${complaint}/complaint`, tmpValues)
    if (res.code === 201) {
      const complaintId = res?.data?.id
      setsubmit({ status: 1 })
      formik.resetForm()
      formikUpdate.resetForm()
      setCover([])
      const getComplaintById = async () => {
        const resComplaintId = await getData({
          schema: `${complaint}/complaint/` + complaintId,
          params: {},
          url: '',
          baseURL: '',
        })
        if (resComplaintId) {
          if (resComplaintId.code === 200) {
            const payload = {
              address: resComplaintId?.data?.address,
              category_id: resComplaintId?.data?.category?.id,
              channel: resComplaintId?.data?.channel,
              complaint_name: resComplaintId?.data?.complaint_name,
              coordinates: {
                latitude: resComplaintId?.data?.coordinates?.latitude,
                longitude: resComplaintId?.data?.coordinates?.longitude,
              },
              // department_data: formikUpdate?.values?.department_data,
              // description: formikUpdate?.values?.description,
              description: resComplaintId?.data?.description,
              district_id: resComplaintId?.data?.district_id,
              duedate_at: formikUpdate?.values?.duedate_at,
              line_id: resComplaintId?.data?.line_id,
              media_url: resComplaintId?.data?.media_url,
              mobile_number: resComplaintId?.data?.mobile_number,
              name: {
                first_name: resComplaintId?.data?.name?.first_name,
                last_name: resComplaintId?.data?.name?.last_name,
              },
              org_id: '19118133238380',
              province_id: resComplaintId?.data?.province_id,
              sub_category_id: resComplaintId?.data?.sub_category_data?.id,
              sub_district_id: resComplaintId?.data?.sub_district_id,
              urgency_id: formikUpdate?.values?.urgency_id,
            }
            const res = await putData(`${complaint}/complaint`, resComplaintId?.data?.id, payload)
            if (res) {
              if (res?.code === 201 || 200) {
                formik.resetForm()
                formikUpdate.resetForm()
                openNotification()
                closeInputDrawer()
                getListData()
              } else {
              }
            }
          }
        }
      }
      getComplaintById()
    }
  }

  const handlerSubmitUpdate = async () => {
    const payload = {
      address: complaintFromApi?.address,
      category_id: complaintFromApi?.category?.id,
      channel: complaintFromApi?.channel,
      complaint_name: complaintFromApi?.complaint_name,
      coordinates: {
        latitude: complaintFromApi?.coordinates?.latitude,
        longitude: complaintFromApi?.coordinates?.longitude,
      },
      department_data: formikUpdate?.values?.department_data,
      description: formikUpdate?.values?.description,
      district_id: complaintFromApi?.district_id,
      duedate_at: formikUpdate?.values?.duedate_at,
      line_id: complaintFromApi?.line_id,
      media_url: complaintFromApi?.media_url,
      mobile_number: complaintFromApi?.mobile_number,
      name: {
        first_name: complaintFromApi?.name?.first_name,
        last_name: complaintFromApi?.name?.last_name,
      },
      org_id: '19118133238380',
      province_id: complaintFromApi?.province_id,
      sub_category_id: complaintFromApi?.sub_category_data?.id,
      sub_district_id: complaintFromApi?.sub_district_id,
      urgency_id: formikUpdate?.values?.urgency_id,
    }

    const res = await putData(`${complaint}/complaint`, complaintFromApi?.id, payload)

    if (res) {
      if (res?.code === 201 || 200) {
        formik.resetForm()
        openNotification()
        closeInputDrawer()
        getListData()
      } else {
      }
    }
  }

  const handleDatePickerChange = (date, dateString) => {
    const unixTimestamp = moment(date).unix()
    formikUpdate.setFieldValue('duedate_at', unixTimestamp)
  }

  const onOpenConfirmModal = () => {
    setConfirmModalOpen(true)
  }

  const onCloseConfirmModal = () => {
    setConfirmModalOpen(false)
  }

  const checkArear = val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }

  const handleClickMap = event => {
    if (isModalOpen) {
      const cord = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      if (checkArear(cord)) {
        setAutocompleteValue('')
        setSearchValue('')
        setClickedLatLng({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setCenterMap({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setZoomMap(14)
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  const handleOk = () => {
    if (clickedLatLng?.lat || clickedLatLng?.lng) {
      setIsModalOpen(false)
      // handleChangeForm()
    } else {
      if (checkArear(meLocation)) {
        setClickedLatLng({
          lat: meLocation?.lat,
          lng: meLocation?.lng,
        })
        setIsModalOpen(false)
        // handleChangeForm()
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    // setSearchValue('')
  }

  return (
    <>
      {!createSuccess ? (
        <>
          <div style={{ height: '4.5rem', position: 'relative' }}>
            <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
              <img src={IconClear} alt="" />
            </div>
          </div>

          <F.Box>
            <F.BoxTitle>
              <IconTitle />
              <F.TextTitle>นำเข้าเรื่องร้องเรียน</F.TextTitle>
            </F.BoxTitle>
            <InputFormUser
              label="วันที่"
              name="date"
              placeholder="กรอกชื่อ"
              type="text"
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              values={formattedDate}
              defaultValues={formattedDate}
              disabled={true}
              labelSize="12px"
              className="nobottom"
            />
            <div className="wrap-box-name">
              <div style={{ width: '100%', marginRight: '24px' }}>
                <InputFormUser
                  label="ชื่อ"
                  name="first_name"
                  placeholder="โปรดระบุชื่อ ของผู้ร้องเรียน"
                  type="text"
                  required={true}
                  errors={formik.errors}
                  touched={formik.touched}
                  form={formik}
                  status={formik.status}
                  maxLength={255}
                  labelSize="12px"
                  className="nobottom"
                />
              </div>
              <div style={{ width: '100%' }}>
                <InputFormUser
                  label="นามสกุล"
                  name="last_name"
                  placeholder="โปรดระบุนามสกุล ของผู้ร้องเรียน"
                  type="text"
                  required={true}
                  errors={formik.errors}
                  touched={formik.touched}
                  form={formik}
                  status={formik.status}
                  maxLength={255}
                  labelSize="12px"
                  className="nobottom"
                />
              </div>
            </div>
            <InputFormUser
              label="เบอร์โทร"
              name="mobile_number"
              placeholder="0XX-XXX-XXXX"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              // maxLength={12}
              onChange={e => onPhoneNumberChange(e)}
              labelSize="12px"
              className="nobottom"
            />
            <div style={{ width: '90%', minHeight: '102px' }}>
              <F.LabelText>
                ประเภทเรื่องร้องเรียน
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm2
                  title="ประเภทเรื่องร้องเรียน"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('category_id', value)
                    setCategoryId(value)
                    formik.setFieldValue('sub_category_id', '')
                  }}
                  className="big-input"
                  options={optionsCategories}
                  value={formik?.values?.category_id}
                />
              </F.StyleDropdown>
            </div>
            <div style={{ width: '90%', minHeight: '102px' }}>
              <F.LabelText>
                ประเภทย่อย
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm2
                  title="ประเภทย่อย"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    setSelectSubCategory(value)
                    formik.setFieldValue('sub_category_id', value)
                  }}
                  className="big-input"
                  options={optionsSubCategories}
                  value={formik?.values?.sub_category_id}
                />
              </F.StyleDropdown>
            </div>
            <InputCustom
              label="รายละเอียดเพิ่มเติม (ถ้ามี)"
              name="description"
              placeholder="กรอกรายละเอียด"
              type="textarea"
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              // maxLength={255}
              // disabled
            />
            <div style={{ width: '90%', minHeight: '102px' }}>
              <F.LabelText>
                ช่องทางการนำเข้าข้อมูล
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm2
                  title="ช่องทางการนำเข้าข้อมูล"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('channel', value)
                  }}
                  className="big-input"
                  options={optionsChannel}
                  value={formik?.values?.channel}
                  typeSelect={'channel'}
                />
              </F.StyleDropdown>
            </div>
            <div className="row-data">
              <div className="col-1">
                <div>
                  <F.LabelTextUpload>อัปโหลดรูปภาพหรือวิดีโอ</F.LabelTextUpload>
                  <span
                    style={{ fontSize: '10px', color: '#001f34', fontFamily: 'Prompt' }}
                  >{` (อัปโหลดได้ไม่เกิน 3 ไฟล์)`}</span>
                  <span className="" style={{ float: 'right' }}>
                    <F.CustomTooltip
                      placement="leftTop"
                      title="ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”"
                      // defaultOpen={true}
                      // open={true}
                    >
                      <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                    </F.CustomTooltip>
                  </span>
                  <F.CustomUploadfile>
                    <UploadFile
                      accept={{
                        'image/jpeg': [],
                        'image/png': [],
                        'video/mp4': [],
                      }}
                      keys={1}
                      files={cover}
                      isCrop={false}
                      maxFile={3}
                      maxSize={1024 * 1024 * 5}
                      typeUpload={'imagevdo'}
                      text={'ไฟล์ที่อัปโหลดต้องเป็นไฟล์นามสกุล JPEG , PNG , MP4 และ มีขนาดแต่ละไฟล์ไม่เกิน 10 MB'}
                      // abortController={abortController}
                      onFileRetuen={(files, file) => {
                        let _image = [...files]
                        _image.push(file)
                        setCover(_image)
                      }}
                      onFileRemove={(files, res) => {
                        // setAbortController(new AbortController())
                        let _image = [...files]
                        _image = _image.filter((r, i) => i !== res.index)
                        setCover(_image)
                      }}
                    />
                  </F.CustomUploadfile>
                </div>
              </div>
            </div>
            <div>
              <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>
              <Viewmap
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                clickedLatLng={clickedLatLng}
                setClickedLatLng={setClickedLatLng}
                meLocation={meLocation}
                address={address[2]?.formatted_address}
                setIsModalOpenError={setIsModalOpenError}
                handleOk={handleOk}
                handleClickMap={handleClickMap}
                checkArear={checkArear}
                centerMap={centerMap}
                setCenterMap={setCenterMap}
                setZoomMap={setZoomMap}
                zoomMap={zoomMap}
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={setAutocompleteValue}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                arrArear={formAntd?.getFieldValue('category_id') === '1735509869718401030' ? arrArearWater : arrArear}
              />
            </div>
            <div
              style={{ marginTop: '40px', width: '100%', height: 'auto', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button htmlType="submit" disabled={!isValidForm} className="bt-next" onClick={() => onClickNext()}>
                ถัดไป
              </Button>
            </div>
          </F.Box>
        </>
      ) : (
        <F.Box>
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '10px', alignItems: 'center', marginBottom: '10px' }}
          >
            <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setCreateSuccess(false)} />
            <h2 className="title" style={{ margin: '0' }}>
              กำหนดรายละเอียดเรื่องร้องเรียน
            </h2>
          </div>

          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              วันที่กำหนดเสร็จ
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <ThaiDatePicker onChange={handleDatePickerChange} value={formikUpdate?.values?.duedate_at} />
            </F.StyleDropdown>
          </div>
          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              สถานะความเร่งด่วน
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <DropdownForm2
                title="สถานะความเร่งด่วน"
                placeholder="สถานะความเร่งด่วน"
                showSearch={false}
                required={true}
                onChange={value => {
                  formikUpdate.setFieldValue('urgency_id', value)
                }}
                value={formikUpdate?.values?.urgency_id}
                className="big-input"
                options={optionsUrgency}
              />
            </F.StyleDropdown>
          </div>
          {/* <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              หน่วยงานที่รับผิดชอบ
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <DropdownMultiple
                title="หน่วยงานที่รับผิดชอบ"
                placeholder="กรุณาเลือกหน่วยงาน"
                mode="multiple"
                showSearch={false}
                required={true}
                onChange={value => {
                  formikUpdate.setFieldValue('department_data', value)
                }}
                value={formikUpdate?.values?.department_data}
                className="big-input"
                options={optionsOrganizations}
              />
            </F.StyleDropdown>
          </div> */}

          {/* <InputCustom
            label="รายละเอียดเพิ่มเติม (แสดงผลที่ Line OA ผู้ใช้งาน)"
            name="description"
            placeholder="ระบุเหตุผลเพื่อแสดงผลที่ผู้ใช้งาน"
            type="textarea"
            errors={formikUpdate.errors}
            touched={formikUpdate.touched}
            form={formikUpdate}
            maxLength={255}
          /> */}

          <F.BoxButton>
            <Space>
              <BtSubmit onClick={() => setLeaveModalOpen(true)} bttype="cancel">
                ยกเลิก
              </BtSubmit>
              <BtSubmit onClick={() => onOpenConfirmModal()} disabled={!isValidUpdate}>
                ยืนยัน
              </BtSubmit>
            </Space>
          </F.BoxButton>
        </F.Box>
      )}

      <F.CustomModal
        transitionName=""
        centered
        open={comfirmModalOpen}
        onOk={() => {
          setConfirmModalOpen(false)
          handlerSubmit()
        }}
        onCancel={() => setConfirmModalOpen(false)}
        closable={false}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        zIndex={1100}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p
          style={{
            color: '#001F34',
            fontSize: '16px',
            marginBottom: '2px',
            fontWeight: '400',
          }}
        >
          ยืนยันการนำเข้าข้อมูลเรื่องร้องเรียน
        </p>
        <p
          style={{
            color: '#597081',
            fontSize: '12px',
            fontWeight: '300',
          }}
        >
          หากคุณนำเข้าข้อมูลเรื่องร้องเรียนนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
        </p>
      </F.CustomModal>

      <Modal
        open={isModalOpen}
        closeIcon={<CloseModelMap onClick={handleCancel} />}
        className="model-map-create-complant"
        title="เลือกตำแหน่งที่ตั้ง"
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
        style={{ borderRadius: '10px' }}
        zIndex={1051}
        centered
      >
        <Viewmap
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          clickedLatLng={clickedLatLng}
          setClickedLatLng={setClickedLatLng}
          meLocation={meLocation}
          address={address[2]?.formatted_address}
          arrArear={formAntd?.getFieldValue('category_id') === '1735509869718401030' ? arrArearWater : arrArear}
          setIsModalOpenError={setIsModalOpenError}
          handleOk={handleOk}
          handleClickMap={handleClickMap}
          checkArear={checkArear}
          centerMap={centerMap}
          setCenterMap={setCenterMap}
          setZoomMap={setZoomMap}
          zoomMap={zoomMap}
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </Modal>
      <Modal
        closable={false}
        open={isModalOpenError}
        footer={null}
        className="model-map-create-complant-error"
        centered
        zIndex={9999}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Prompt , sans-serif',
            textAlign: 'center',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '15%',
              }}
            >
              <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
            </div>
            <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
            <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>
            <F.BoxBtnInMap>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{ width: '100px' }}
                  onClick={() => {
                    setIsModalOpenError(false)
                    setloaddingCraete(false)
                  }}
                >
                  ตกลง
                </Button>
              </div>
            </F.BoxBtnInMap>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FormInput

const BtSubmit = styled(Button)`
  background-color: ${props => (props.bttype === 'cancel' ? '#fff' : '#fc6521')};
  border: 1px solid #fc6521 !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${props => (props.bttype === 'cancel' ? '#fc6521' : 'white')};
  /* line-height: 20px; */
  /* padding: 18px 8px; */
  width: 120px;
  height: 48px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: #fc6521 !important;
    background-color: ${props => (props.bttype === 'cancel' ? 'white' : '#fc6521')} !important;
    color: ${props => (props.bttype === 'cancel' ? '#fc6521' : 'white')};
  }

  &:disabled {
    background-color: #fee0d3 !important;
    border-color: #fee0d3 !important;
    color: white !important;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #fee0d3 !important;
    border-color: #fee0d3 !important;
    color: white !important;
    cursor: not-allowed;
  }

  /* @media only screen and (max-width: 768px) {
    height: 48px;
  }

  @media only screen and (max-width: 375px) {
    height: 48px;
  } */
`
