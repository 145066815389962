import IconSee from 'assets/images/svg/see-icon.svg'
import IconEdit from 'assets/images/svg/edit-icon.svg'
import IconDel from 'assets/images/svg/del-icon.svg'
import IconTran from 'assets/images/svg/tran-icon.svg'
import IconUpdate from 'assets/images/svg/update-icon.svg'
import IconCannot from 'assets/images/svg/cannot-icon.svg'
import iconDelete from 'assets/images/svg/icon-bin-org.svg'

export const convertAtivityThai = val => {
  if (val === 'view') {
    return 'ดูข้อมูล'
  }
  if (val === 'edit') {
    return 'แก้ไขข้อมูล'
  }
  if (val === 'update process') {
    return 'อัปเดตข้อมูล'
  }
  if (val === 'transfer') {
    return 'โอนย้าย'
  }
  if (val === 'decline') {
    return 'ปฏิเสธการโอนย้าย'
  }

  if (val === 'import') {
    return 'นำเข้าข้อมูล'
  }

  if (val === 'delete') {
    return 'ลบข้อมูล'
  }
}

export const convertAtivityIcon = val => {
  if (val === 'view') {
    return IconSee
  }
  if (val === 'edit') {
    return IconEdit
  }
  if (val === 'update process') {
    return IconUpdate
  }
  if (val === 'transfer') {
    return IconTran
  }
  if (val === 'import') {
    return IconTran
  }
  if (val === 'decline') {
    return IconCannot
  }
  if (val === 'delete') {
    return iconDelete
  }
}
