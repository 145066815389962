import { Table } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { conversDate, ThaiFormattedDate } from 'core/utils/DateTimeFormat'
import UserContext from 'core/contexts/userContext'
import { checkColorStatusBg, checkColorStatusText, checkStatusText } from './container'

export default function ExportPdf({ dataSource, componentRefAll, searchData }) {
  const { meData } = useContext(UserContext)
  const chunkArray = (arr, size) => {
    const chunkedArray = []
    for (let i = 0; i < arr.length; i += size) {
      chunkedArray.push(arr.slice(i, i + size))
    }
    return chunkedArray
  }

  const pageSize = 20
  const subArrays = chunkArray(dataSource, pageSize)
  const colums = [
    {
      title: <div style={{ fontSize: '10px', textAlign: 'center' }}>ลําดับ</div>,
      dataIndex: 'no',
      width: '50px',
      render: (text, data) => <div style={{ fontSize: '10px', textAlign: 'center' }}>{text}</div>,
    },
    {
      title: <div style={{ fontSize: '10px' }}>บริการสาธารณะ</div>,
      dataIndex: 'title_complaint',
    },
    {
      title: <div style={{ fontSize: '10px' }}>เลขที่บริการสาธารณะ</div>,
      dataIndex: 'complaint_no',
    },
    {
      title: <div style={{ fontSize: '10px' }}>ประเภทบริการสาธารณะ</div>,
      dataIndex: 'type_of_complaint',
      width: '150px',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.type_of_complaint?.icon_url && (
            <img src={data?.type_of_complaint?.icon_url} style={{ width: '14px', height: '14px' }} />
          )}
          {data?.type_of_complaint?.name ? data?.type_of_complaint?.name : '-'}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize: '10px' }}>ผู้รับผิดชอบ</div>,
      dataIndex: 'sub_category_name',
      width: '120px',
      render: (text, data) => {
        const staffData = data?.department_data
        return (
          <div className={staffData?.officer_data?.id === meData?.id ? 'responsible-me' : 'responsible-other'}>
            {staffData?.officer_data !== null
              ? `${staffData?.officer_data?.first_name} ${staffData?.officer_data?.last_name}`
              : '-'}
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '10px' }}>วันที่ขอรับบริการ</div>,
      dataIndex: 'created_at',
      width: '80px',
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },
    {
      title: 'สถานะการให้บริการ',
      render: (text, data) =>
        data?.status_data ? (
          <span
            style={{
              width: 'auto',
              height: '24px',
              background: checkColorStatusBg(data?.status_data?.name),
              borderRadius: '4px',
              textAlign: 'center',
              padding: '6px',
              color: checkColorStatusText(data?.status_data?.name),
            }}
          >
            {data?.status_data.name ? checkStatusText(data?.status_data?.name) : '-'}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: <div style={{ fontSize: '10px' }}>ช่องทาง</div>,
      dataIndex: 'channel',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '10px' }}>ผู้ขอรับบริการ</div>,
      render: (text, data) => (
        <div style={{ color: '#717171', fontSize: '12px', fontWeight: '400' }}>
          {data?.name?.first_name} {data?.name?.last_name}
        </div>
      ),
    },
  ]

  return (
    <Box ref={componentRefAll}>
      <div>
        {subArrays.map((value, index, array) => {
          return (
            <div key={index} style={{ margin: '2%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <div>
                  <div style={{ textAlign: 'center' }}>รายงานบริการสาธารณะ</div>
                  <div style={{ textAlign: 'center' }}>
                    ประจำวันที่ {ThaiFormattedDate(searchData?.date[0])} ถึง {ThaiFormattedDate(searchData?.date[1])}
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>บริการสาธารณะ</div>
              <Table dataSource={value} columns={colums} pagination={false} />
              <div style={{ pageBreakAfter: 'always' }} />
            </div>
          )
        })}
      </div>
    </Box>
  )
}
export const Box = styled.div`
  padding: 1%;
  font-family: 'Prompt' !important;
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 5px !important;
    overflow-wrap: break-word;
    font-size: 10px;
  }
`
