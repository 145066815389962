import React from 'react'
import styled from 'styled-components'
export default function ExportReportComplaintPdf({ dataSource, componentRefAll, searchData }) {
  const mockData = [
    {
      key: '1',
      order: 1,
      type_complaint: 'ไฟฟ้าสาธารณะ',
      total: 100,
      done: 100,
      doing: 0,
      percent: 100,
    },
    {
      key: '2',
      order: 2,
      type_complaint: 'ถนน/ฟุตบาททางเท้า',
      total: 100,
      done: 100,
      doing: 0,
      percent: 100,
    },
    {
      key: '3',
      order: 3,
      type_complaint: 'ควบคุมการก่อสร้าง',
      total: 100,
      done: 100,
      doing: 0,
      percent: 100,
    },
  ]
  return (
    <BoxTable ref={componentRefAll}>
      <div style={{ textAlign: 'center' }}>
        ผลดำเนินการแก้ไขเรื่องร้องเรียนผ่านระบบรับแจ้งเรื่องร้องเรียนประจำเดือน{' '}
      </div>
      <div style={{ textAlign: 'center' }}>จัดทำข้อมูลวันที่</div>
      <div>หน่วยงาน</div>
      <div>สำนัก </div>
      <div>
        <table style={{ width: '100%' }}>
          <tr>
            <th>ลำดับ</th>
            <th>ประเภทเรื่องร้องเรียน</th>
            <th>จำนวนเรื่อง</th>
            <th>ดำเนินการแล้วเสร็จ</th>
            <th>ระหว่างดำเนินการ</th>
            <th>คิดเป็นร้อยละ</th>
          </tr>
          {mockData?.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td>{item?.order}</td>
                  <td>{item?.type_complaint}</td>
                  <td>{item?.total}</td>
                  <td>{item?.done}</td>
                  <td>{item?.doing}</td>
                  <td>{item?.percent}</td>
                </tr>
              </>
            )
          })}
        </table>
        <div className="box-footer">
          <div className="box-footer-table">
            <div>เรื่องขอรับบริการประจำเดือน</div>
            <div>จำนวน</div>
            <div>100</div>
            <div>เรื่อง</div>
          </div>
          <div className="box-footer-table">
            <div>ดำเนินการแล้วเสร็จ</div>
            <div>จำนวน</div>
            <div>100</div>
            <div>เรื่อง</div>
            <div>ร้อยละ</div>
            <div>100</div>
          </div>
          <div className="box-footer-table">
            <div>ระหว่างดำเนินการ</div>
            <div>จำนวน</div>
            <div>100</div>
            <div>เรื่อง</div>
            <div>ร้อยละ</div>
            <div>100</div>
          </div>
        </div>

        {/* <table style={{ width: '100%' }}>
          <tr>
            <th>หน่วยงาน</th>
            <th>ลำดับ</th>
            <th>ประเภทเรื่องร้องเรียน</th>
            <th>จำนวนเรื่อง</th>
            <th>ดำเนินการแล้วเสร็จ</th>
            <th>ระหว่างดำเนินการ</th>
            <th>คิดเป็นร้อยละ</th>
          </tr>
          {mockData?.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td rowSpan={item?.data?.length + 2}>{item?.orgenization}</td>
                </tr>
                {item?.data?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{item?.order}</td>
                        <td>{item?.type_complaint}</td>
                        <td>{item?.total}</td>
                        <td>{item?.done}</td>
                        <td>{item?.doing}</td>
                        <td>{item?.percent}</td>
                      </tr>
                    </>
                  )
                })}
                <tr>
                  <td colSpan="2">รวม</td>
                  <td>{item?.total.total}</td>
                  <td>{item?.total.done}</td>
                  <td>{item?.total.doing}</td>
                  <td>{item?.total.percent}</td>
                </tr>
              </>
            )
          })}
        </table> */}
      </div>
    </BoxTable>
  )
}

const BoxTable = styled.div`
  padding: 2%;
  font-family: 'Prompt' !important;
  @media print {
    @page {
      size: A4 landscape;
      margin: 20mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  table,
  td,
  th {
    border: 1px solid #000;
    text-align: center;
  }
  .box-footer-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: left;
  }
  .box-footer {
    margin-top: 2%;
  }
`
